import { App, ConfigProvider } from 'antd';
import "bootstrap/dist/js/bootstrap.min.js";
import type { GatsbyBrowser } from "gatsby";
import React from "react";


export const wrapRootElement: GatsbyBrowser["wrapPageElement"] = ({ element }) => {
    return (
        <App>
            <ConfigProvider theme={{
                token: {
                    colorPrimary: "#001529",
                },
            }}>
                {element}
            </ConfigProvider >
        </App>
    );
};